<template>
  <div class="main">
    <section class="bordered padded">
      <h1><translate translate-context="*/*/Noun">Accounts</translate></h1>
      <p><translate translate-context="*/*/*">This is a list of all accounts that can connect to Alta Call TSM management user interface. You can invite new team members using the form below.</translate></p>
      <form @submit.prevent="create">
        <h2>Invite a team member</h2>
        <div class="stackable row">
          <div class="column">
            <div class="field">
              <label for="email">
                <translate translate-context="*/*/*">Email</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="email" />
              <input type="email" id="email" name="email" v-model="invitation.email">
            </div>
            <div class="field">
              <label for="group">
                <translate translate-context="*/*/*/Noun">Group</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="group" />
              <select
                name="group-menu"
                id="group-menu"
                v-model="invitation.group"
              >
                <option
                  v-for="group in $store.state.groups"
                  :key="group.id" :value="group.id"
                >
                  {{ group.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="column">
            <label>
              <translate translate-context="*/*/*">Permissions</translate>
            </label>
            <p>
              <translate translate-context="*/*/*">Accounts without these permissions will still be able to view and manage resources within their groups.</translate>
            </p>
      
            <field-errors :errors="errors.fieldErrors" field="roles" />
            <div class="checkbox field mt-regular">
              <input
              type="checkbox"
              name="role_admin"
              id="role_admin"
              value="client-admin"
              v-model="invitation.roles"><label for="role_admin">
                <translate translate-context="*/Button/Verb">Admin</translate>
              </label>
              <p class="discrete mt-0">
                <translate translate-context="*/*/*">Manage everything within your organization including abtests, billing, users, machines and inviting other members.</translate>
              </p>
            </div>
          </div>
        </div>
        <app-button :is-loading="isCreating" type="submit">
          <translate translate-context="*/Button/Verb">Invite</translate>
        </app-button>
      </form>
      <h2>Existing accounts</h2>
      <loading-area class="opaque padded bordered" v-if="isLoading" :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading accounts…</translate></span>
      </loading-area>
      <table v-else-if="results">
        <thead>
          <th><translate translate-context="*/*/*">Email</translate></th>
          <th><translate translate-context="*/*/*">Name</translate></th>
          <th><translate translate-context="*/*/*">Joined date</translate></th>
          <th><translate translate-context="*/*/*">Email Verified</translate></th>
          <th><translate translate-context="*/*/*">Permissions</translate></th>
          <th><translate translate-context="*/*/*">Actions</translate></th>
        </thead>
        <tbody>
          <tr v-for="user in results.results" :key="user.email + user.last_name">
            <td>
              <a :href="`mailto:{user.email}`">{{ user.email }}</a>
            </td>
            <td>{{ user.last_name }} {{ user.name }}</td>
            <td>{{ ago(user.creation_date) }}</td>
            <td v-if="user.email_verified">
              <app-icon name="check"></app-icon> <translate translate-context="*/*/*">Yes</translate>
            </td>
            <td v-else>
              <app-icon name="close"></app-icon> <translate translate-context="*/*/*">No</translate>
            </td>
            <td>
              <template v-for="permission in getPermissions(user.roles)" :key="permission">{{ permission }}<br></template>
            </td>
            <td>
              <button class="link" @click.prevent="toDeleteUser = user; showDeleteModal = true">
                <translate translate-context="*/*/*">Delete user…</translate>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <app-modal v-if="showDeleteModal" @close="showDeleteModal = false" :submit-action="deleteUser">
        <template v-slot:header>
          <h3>
            <translate translate-context="*/*/*" :translate-params="{email: toDeleteUser.email}">Delete user %{ email }</translate>
          </h3>
        </template>
        <p>
          <translate translate-context="*/*/*">By clicking deleting this account, it will removed immediately and completely from our database.</translate>
        </p>
        <p>
          <translate translate-context="*/*/*">It may take up to 15 minutes for the account to be disconnected and loose complete access to the dashboard.</translate>
        </p>
        <p>
          <strong>
            <translate translate-context="*/*/*">This action is irreversible, please proceed with caution.</translate>
          </strong>
        </p>
        <template v-slot:action-button>
          <app-button :is-loading="isDeleting" @click.prevent="deleteUser">
            <translate translate-context="*/Button/Verb">Delete</translate>
          </app-button>
        </template>
      </app-modal>
    </section>
  </div>
</template>
<script>

import http from '@/http'
import { ago } from '@/time'

export default {
  data () {
    return {
      errors: {},
      isLoading: false,
      isCreating: false,
      results: null,
      toDeleteUser: null,
      isDeleting: false,
      ago,
      showDeleteModal: false,
      filters: {...this.$route.query},
      invitation: {
        email: null,
        roles: [],
        group: this.$store.state.selectedGroupId
      }
    }
  },
  async created () {    
    await this.fetchData(this.filters)
  },
  methods: {
    getPermissions (roles) {
      let mapping = {
        "client-admin": "Admin",
      }
      return roles.map((r) => {
        return mapping[r] || r
      })
    },
    async deleteUser () {
      if (!this.toDeleteUser || !this.toDeleteUser.email) {
        return
      }
      this.isDeleting = true
      try {
        await http.delete(`it/accounts/${this.toDeleteUser.email}`)
        await this.fetchData({})
      } finally {
        this.isDeleting = false
        this.toDeleteUser = null
        this.showDeleteModal = false
      }
    },
    async fetchData(filters) {
      let response
      this.isLoading = true
      this.errors = {}
      this.results = null
      try {
        response = await http.get('it/accounts', {params: {...filters}})
        this.results = response.data
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async create() {
      let response
      this.isCreating = true
      this.errors = {}
      try {
        response = await http.post('it/accounts', this.invitation)
        this.results = response.data
        this.invitation.email = null
        await this.fetchData({})
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isCreating = false
      }
    },
  }
}
</script>
